/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { quilt_vertShader, quilt_fragShader } from '../shaders/quilt_shader'
import { Glass_Reflection, Mirror_Reflection } from '../mirrors'

export default function LKG_4k({...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/LKG_4K.glb')

  props.aspect 
 

  var test_material = new THREE.ShaderMaterial({
    uniforms: {
      quiltTexture: { value: props.quilt },
      tilesX: { value: props.columns },
      tilesY: { value: props.rows },
      aspectScaler: {value: new THREE.Vector2(((3840/2160)/props.aspect), 1)},
      quiltViewPortion: { value: new THREE.Vector2(4095.0 / 4096.0, 4095.0 / 4096.0) }
    },
    vertexShader: quilt_vertShader,
    fragmentShader: quilt_fragShader
  })


  // assign shader  to display and adjust glass transparency/color
  materials.Display = test_material

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[1.13446, 3.14 / 2, 0]} position={[0, 5, 0.1]}>
          <Glass_Reflection height={7.8} width={13.4}/>
      </group>
        {/* Top Mirror */}
      <group rotation={[2.61799, 3.14 / 2, 0]} position={[0, 8.4, -0.7]}>
        <Mirror_Reflection height={1.3} width={13.4} />
      </group>
        {/* Bottom Mirror */}
        <group rotation={[5.81195, 3.14 / 2, 0]} position={[0, 1.45, 1.9]}>
        <Mirror_Reflection height={2.1} width={14} />
      </group>
        {/* Right Side Mirror */}
      <group rotation={[5.84685, 0, 1.5708]} position={[6.65, 5, 0.8]}>
        <Mirror_Reflection height={7.9} width={1.3} />
      </group>
       {/* Left Side Mirror */}
       <group rotation={[5.84685, 0, -1.5708]} position={[-6.65, 5, 0.8]}>
        <Mirror_Reflection height={7.9} width={1.3} />
      </group>
      <group scale={[39.37, 39.37, 39.37]}>
        <mesh
          geometry={nodes.Rectangle001.geometry}
          material={nodes.Rectangle001.material}
          position={[0, 0.10936, -0.00476]}
          rotation={[1.13446, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.Rectangle002.geometry}
          material={nodes.Rectangle002.material}
          position={[0.00016, 0.09458, 0.00766]}
          rotation={[1.13446, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_4K_PANEL.geometry}
          material={nodes.LKG_4K_PANEL.material}
          position={[0.00016, 0.09458, 0.00766]}
          rotation={[1.13446, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_4K_Panel001.geometry}
          material={nodes.LKG_4K_Panel001.material}
          position={[0.00016, 0.09458, 0.00766]}
          rotation={[1.13446, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_4K_Button3.geometry}
          material={nodes.LKG_4K_Button3.material}
          position={[0.18919, 0.10878, 0.02998]}
          rotation={[-0.43633, -0.17453, Math.PI / 2]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_4K_Button2.geometry}
          material={nodes.LKG_4K_Button2.material}
          position={[0.18919, 0.14775, 0.01181]}
          rotation={[-0.43633, -0.17453, Math.PI / 2]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_4K_Button1.geometry}
          material={nodes.LKG_4K_Button1.material}
          position={[0.18919, 0.16859, 0.00209]}
          rotation={[-0.43633, -0.17453, Math.PI / 2]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.Object001.geometry}
          material={nodes.Object001.material}
          position={[0.18919, 0.16859, 0.00209]}
          rotation={[-0.43633, -0.17453, Math.PI / 2]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.Rectangle003.geometry}
          material={materials['Material.002']}
          position={[0.00782, 0.00013, -0.01296]}
          rotation={[-Math.PI, 0, 0]}
          scale={0.0254}
        />
        <mesh
          geometry={nodes.LKG_4K_Display.geometry}
          material={materials.Display}
          position={[0, 0.10936, -0.00476]}
          rotation={[1.13446, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_4K_MIRROR.geometry}
          material={materials.Mirror}
          position={[0, 0.10936, -0.00476]}
          rotation={[1.13446, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_LED_RING.geometry}
          material={nodes.LKG_LED_RING.material}
          position={[0, 0.10936, -0.00476]}
          rotation={[1.13446, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.Object002.geometry}
          material={nodes.Object002.material}
          position={[0.18919, 0.14775, 0.01181]}
          rotation={[-0.43633, -0.17453, Math.PI / 2]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.Object003.geometry}
          material={nodes.Object003.material}
          position={[0.18919, 0.10878, 0.02998]}
          rotation={[-0.43633, -0.17453, Math.PI / 2]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/LKG_4K.glb')
