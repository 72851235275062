import * as THREE from 'three'

export function checkImage(url, setQuilt, setColumns, setRows, vid) {
  var request = new XMLHttpRequest()
  request.open('GET', url, true)
  request.send()
  request.onload = function () {
    if (request.status == 200 && url != undefined) {
      console.log('image exists 💃')
      QuiltfromURL(url, setQuilt, setColumns, setRows, vid)
    } else {
      console.log("image doesn't exist in URL")
    }
  }
}

function QuiltfromURL(url, setQuilt, setColumns, setRows, vid) {
  if (url != undefined) {
    // get columns from file name
    console.log(url, 'quilt name')
    var regexClean = RegExp(/qs[0-9]{1,2}x[0-9]{1,2}/)
    let regexTest = regexClean.test(url)
    console.log(regexTest)
    let resultClean = regexTest ? regexClean.exec(url) : regexClean.exec('banana_qs8x6') //if regex is valid use url params, if not default to 8x6
    var regexNums = resultClean[0].match(/\d+/g)
    console.log(regexNums[0], 'columns')
    console.log(regexNums[1], 'rows')
    setColumns(regexNums[0])
    setRows(regexNums[1])
    QuiltToTexture(url, setQuilt, vid)
  } else {
    console.log('url is undefined or lacks proper name, setting parameters to 8 columns & 6 rows')
    setColumns(8)
    setRows(6)
  }
}

function QuiltToTexture(url, setQuilt, vid) {
  if (url != undefined) {
    console.log(url, 'quilt is defined')
    var quilt_tex = new THREE.TextureLoader().load(url)
    setQuilt(quilt_tex)
    quilt_tex.repeat.set = new THREE.Vector2(0, 0)
  } else {
    //   path = '/j_smf_lightfield_qs5x9a0.75.webp'
    //   quilt_tex = new THREE.TextureLoader().load(path)
    //   quilt_tex.repeat.set = new THREE.Vector2(0,0)
  }
}
