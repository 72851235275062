// this file handles file input and name parsing. Currently file types like quilts, lightfields, and rgb-d are handled separately while prototyping.
import * as THREE from 'three'
import { invalidate, useThree } from '@react-three/fiber'

export function LoadQuilt(file, setQuilt, setColumns, setRows, vid,gl) {
  URL.revokeObjectURL(file) //revoke URL for current element
  setQuilt(file)
  var path = ""
  if (file != undefined) {
    // get columns from file name
    path = URL.createObjectURL(file)
    const regexClean = RegExp(/qs[0-9]{1,2}x[0-9]{1,2}/)
    if (regexClean.test(file.name)) {
      const resultClean = regexClean.exec(file.name)
      const regexNums = resultClean[0].match(/\d+/g)
      console.log(regexNums[0], 'columns')
      console.log(regexNums[1], 'rows')
      setColumns(regexNums[0])
      setRows(regexNums[1])
      console.log("your quilt is properly named!🎉")
      QuiltToTexture(file, path, setQuilt, vid,gl)
    }
    else {
     console.log("your quilt is named improperly.😭")
     QuiltToTexture(file, path,setQuilt, vid,gl) // load the file even though the name is wrong
    }
  } else {
    console.log('quilt is undefined or lacks proper name, setting parameters to 8 columns & 6 rows')
    setColumns(8)
    setRows(6)
  }
}

// Convert Quilt into image or video texture depending on file input type. 

// Scratch Pad: 
// This function needs to have a file input. 
// it then parses the file extension to determine if it's an image or video, and sets the appropriate function.
// it needs to return the path to the texture or the texture itself, which is not currently handled in application state. 
// should it just return the entire shader? If this occurs then it also needs to be setup on application start to load the default quilt. 

function QuiltToTexture(quilt, path, setQuilt, vid) {
  if (quilt != undefined) {
    console.log(quilt)
    const type = JSON.stringify(quilt.type)
    if (type.includes('image')) {
      vid.pause()
      const tex = new THREE.TextureLoader().load(path)
      setQuilt(tex)
      invalidate()
    }else if (type.includes('video')) {
      vid.src = path
      vid.crossOrigin = 'Anonymous'
      vid.loop = true
      vid.playsInline = true
      // vid.autoplay = true
      vid.play()
      const video_texture = new THREE.VideoTexture(vid)
      video_texture.format = THREE.RGBAFormat
      setQuilt(video_texture)
      invalidate()
    }
  } else {
    path = '/j_smf_lightfield_qs5x9a0.75.webp'
    setQuilt(new THREE.TextureLoader().load(path))
    invalidate()
  }
}

// WIP lightfield file parsing from mp4, it's a bit slow currently, can be sped up. Needs cleanup.

const camsX = 44
const camsY = 1
const resX = 3008
const resY = 1688
var fieldTexture

export function LoadLightfieldVideo(file, SetLightfieldFile, SetFieldTexture) {
  ExtractVideo(file, SetFieldTexture)
}

export async function ExtractVideo(file, SetFieldTexture) {
  // based on https://stackoverflow.com/questions/32699721/javascript-extract-video-frames-reliably
  const video = document.createElement('video')
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  var path = ''
  canvas.width = resX
  canvas.height = resY
  canvas.setAttribute('id', 'videosrc')
  path = URL.createObjectURL(file)
  video.src = path
  let seekResolve
  let count = 0
  let offset = 0
  const allBuffer = new Uint8Array(resX * resY * 4 * camsX * camsY)

  console.log('starting extraction')

  const getBufferFromVideo = () => {
    ctx.drawImage(video, 0, 0, resX, resY)
    const imgData = ctx.getImageData(0, 0, resX, resY)
    allBuffer.set(imgData.data, offset)
    offset += imgData.data.byteLength
    count++
  }

  const fetchFrames = async () => {
    let currentTime = 0

    while (count < camsX * camsY) {
      getBufferFromVideo()
      currentTime += 0.0333
      video.currentTime = currentTime
      await new Promise((res) => (seekResolve = res))
    }

    var fieldTexture2 = new THREE.DataTexture2DArray(allBuffer, resX, resY, camsX * camsY)
    console.log('Loaded field data')
    SetFieldTexture(fieldTexture2)
  }

  video.addEventListener('seeked', async function () {
    if (seekResolve) seekResolve()
  })

  video.addEventListener('loadeddata', async () => {
    await fetchFrames()
    console.log('loaded data')
  })
}
