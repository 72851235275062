/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as THREE from 'three'
import React, { useRef, useState } from 'react'
import { useThree } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { Glass_Reflection, Mirror_Reflection } from '../mirrors'
import { quilt_vertShader, quilt_fragShader } from '../shaders/quilt_shader'
 
export default function Portrait({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Portrait_1007_2021.glb')


  //check to see if quilt is video to resolve color space issues in three.js 137 hacky workaround for now

  var video;

  if (props.quilt.video != undefined) {
    video = true
  }
  else {
    video = false
  }

  var quilt_material = new THREE.ShaderMaterial({
    flatShading: true,
    uniforms: {
      isvid: { value: video}, // hack to switch color space issue with three.js 137
      quiltTexture: { value: props.quilt },
      tilesX: { value: props.columns },
      tilesY: { value: props.rows },
      aspectScaler: {value: new THREE.Vector2(1, ((2048/1536)*props.aspect))},
      quiltViewPortion: { value: new THREE.Vector2(4095.0 / 4096.0, 4095.0 / 4096.0) },
    },
    vertexShader: quilt_vertShader,
    fragmentShader: quilt_fragShader
  })

  const [play, setPlay] = useState(true)
  const [next, setNext] = useState(true)
  const [previous, setPrevious] = useState(true)

  // assign shader  to display
  materials.Display = quilt_material
  materials.Emmissive.emissiveIntensity = 3
  materials['02 - Default.001'].roughness = .3
  materials.Mirrors.roughness = .3
  // nodes.Mesh010.material.color = new THREE.Color("rgb(5, 5, 5)")

  return (
    <group ref={group} {...props} dispose={null}>
      {/* Mirrors & Reflection Planes */}
      {props.reflections && <group>
        <group rotation={[1.0472, 3.14 / 2, 0]} position={[0, 3.4, 0]}>
          <Glass_Reflection height={6} width={4.6} />
        </group>
        {/* Top Mirror */}
        <group rotation={[2.61799, 3.14 / 2, 0]} position={[0, 6.15, -1.22]}>
          <Mirror_Reflection height={0.75} width={4.6} />
        </group>
        {/* Bottom Mirror */}
        <group rotation={[5.75959, 3.14 / 2, 0]} position={[0, 0.96, 1.77]}>
          <Mirror_Reflection height={0.75} width={4.6} />
        </group>
        {/* Right Side Mirror */}
        <group rotation={[5.75959, 0, 1.5708]} position={[2.25, 3.5, 0.24]}>
          <Mirror_Reflection height={6} width={0.75} />
        </group>
        {/* Left Side Mirror */}
        <group rotation={[5.75959, 0, -1.5708]} position={[-2.25, 3.5, 0.24]}>
          <Mirror_Reflection height={6} width={0.75} />
        </group>
      </group>}
      <group scale={1}>
        <mesh geometry={nodes.BasePlate.geometry} material={nodes.BasePlate.material} position={[0, 0.02, 1.1]} rotation={[-Math.PI, 0, 0]} castShadow receiveShadow/>
        <group position={[2.53, 4, -0.07]} rotation={[Math.PI / 3, 0, -1.37]} onPointerDown={(e) => setPlay(false)} onPointerUp={(e) => setPlay(true)}>
          <mesh geometry={nodes.Mesh014.geometry} material={nodes.Mesh014.material} castShadow receiveShadow/>
          <mesh geometry={nodes.Mesh014_1.geometry} material={nodes.Mesh014_1.material} castShadow receiveShadow/>
          <rectAreaLight width={.46} height={.1} rotation={[0,Math.PI/2,0]} position={[-0.1,0.02,0]} intensity={play ? 0 : 250} color={"white"}/>
        </group>
        <group position={[2.53, 3.04, 0.48]} rotation={[Math.PI / 3, 0, -1.37]} onPointerDown={(e) => setNext(false)} onPointerUp={(e) => setNext(true)}>
          <mesh geometry={nodes.Mesh013.geometry} material={nodes.Mesh013.material} castShadow receiveShadow/>
          <mesh geometry={nodes.Mesh013_1.geometry} material={nodes.Mesh013_1.material} castShadow receiveShadow/>
          <rectAreaLight width={.46} height={.1} rotation={[0,Math.PI/2,0]} position={[-0.1,0.02,0]} intensity={next ? 0 : 250} color={"white"}/>
        </group>
        <group position={[2.53, 4.58, -0.41]} rotation={[Math.PI / 3, 0, -1.37]} onPointerDown={(e) => setPrevious(false)} onPointerUp={(e) => setPrevious(true)}>
          <mesh geometry={nodes.Mesh015.geometry} material={nodes.Mesh015.material} castShadow receiveShadow/>
          <mesh geometry={nodes.Mesh015_1.geometry} material={nodes.Mesh015_1.material} castShadow receiveShadow/>
          <rectAreaLight width={.46} height={.1} rotation={[0,Math.PI/2,0]} position={[-0.1,0.02,0]} intensity={previous ? 0 : 250} color={"white"}/>
        </group>
        <mesh geometry={nodes.Buttons.geometry} material={materials.buttons} position={[2.12, 2.57, -0.39]} rotation={[Math.PI / 3, 0, -1.59]} castShadow receiveShadow/>
        <mesh geometry={nodes.display.geometry} material={materials.Display} position={[-0.03, 3.1, -0.2]} rotation={[Math.PI / 3, 0, 0]}/>
        <group position={[2.12, 2.57, -0.39]} rotation={[Math.PI / 3, 0, -1.59]}>
          <mesh geometry={nodes.Mesh010.geometry} material={nodes.Mesh010.material} castShadow receiveShadow/>
          <mesh geometry={nodes.Mesh010_1.geometry} material={materials.Mirrors}/>
        </group>
        <mesh geometry={nodes.LED.geometry} material={materials.LED} position={[2.12, 2.57, -0.39]} rotation={[Math.PI / 3, 0, -1.59]} castShadow receiveShadow/>
        <mesh geometry={nodes.LED_Frame.geometry} material={materials.Emmissive} position={[-0.03, 3.08, -0.24]} rotation={[Math.PI / 3, 0, 0]} castShadow receiveShadow/>
        <mesh geometry={nodes.mirror.geometry} material={materials.Mirrors} position={[-0.03, 3.1, -0.2]} rotation={[Math.PI / 3, 0, 0]} castShadow receiveShadow/>
        <mesh geometry={nodes.Portrait_Body.geometry} material={materials['02 - Default.001']} position={[-0.03, 3.1, -0.2]} rotation={[Math.PI / 3, 0, 0]} castShadow receiveShadow/>
      </group>
    </group>
  )
}

useGLTF.preload('/Portrait_1007_2021.glb')
