// shader
export var quilt_vertShader = `
   varying vec2 vUv; 
   varying vec3 vNormal;
   varying vec4 vPos; 
   void main() {
       vUv = uv;
       // vUv.y = 1.0 - vUv.y; // why???
       // normal info
       // https://discourse.threejs.org/t/basic-custom-shader-camera/10065
       // not correct, we want to get the normalRay relative to the device.
       vNormal = (modelViewMatrix * vec4(normal, 0.025)).xyz;
       vNormal = normalize(vec3(vNormal.x, 0, vNormal.z));
       gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
       vPos = gl_Position;
   }`

export var quilt_fragShader = `
   uniform sampler2D quiltTexture;
   uniform float tilesX;
   uniform float tilesY;
   uniform vec2 aspectScaler;
   uniform vec2 quiltViewPortion;
   uniform bool isvid;
   varying vec2 vUv;
   varying vec3 vNormal;
   varying vec4 vPos; 
   vec2 texArr(vec3 uvz) {
       // float z = floor(uvz.z * tilesX * tilesY);
       float z = uvz.z;
       float x = (mod(z, tilesX) + uvz.x) / tilesX;
       float y = (floor(z / tilesX) + uvz.y) / tilesY;
       return vec2(x, y) * quiltViewPortion;
   }
   float remap(float value, float from1, float to1, float from2, float to2) {
       return (value - from1) / (to1 - from1) * (to2 - from2) + from2;
   }
   void main() {
       vec3 nuv = vec3(vUv.xy, 0.0);
       nuv.xy *= aspectScaler; // aspect ratio adjustment
       
       //discard pixels outside vUV
       if (nuv.x < 0.0 || nuv.x > 1.0 || nuv.y < 0.0 || nuv.y > 1.0) {
          discard;
        }
       nuv.z = -vNormal.x * 1.6; // now -1 to 1 is like the viewcone
       float fade = nuv.z; // how far off center it is determines fade
       nuv.z = nuv.z * .5 + .5; // 0-1
       nuv.z = clamp(nuv.z, 0.0, 1.0);
       // nuv.z = mod(nuv.z + 4.0, 1.0); // ok really between 0-1 now
       // gl_FragColor = vec4(nuv.z, g, 0.0, 1.0);
       
       // get some samples
       float tiles = tilesX * tilesY;
       nuv.z *= tiles;
       nuv.x += (vPos.x/tilesX)*  0.0000001;
       vec3 uv_1 = nuv;
       uv_1.z = floor(uv_1.z);
       uv_1.z = clamp(uv_1.z, 0.0, tiles - 1.0);
       vec3 uv_2 = nuv;
       uv_2.z = ceil(uv_2.z);
       uv_2.z = clamp(uv_2.z, 0.0, tiles - 1.0);
       float lerp = clamp(mod(nuv.z + 2.0, 1.0), 0.0, 1.0);
       vec4 col1 = texture2D(quiltTexture, texArr(uv_1)); 
       vec4 col2 = texture2D(quiltTexture, texArr(uv_2)); 
       col1 = mix(col1, col2, lerp);
       vec4 privacy = vec4(0.0,0.0,0.0,1.0);
       if (abs(fade) > 1.0) {
           vec3 edgeUv = nuv;
           edgeUv.z = tiles - 1.0;
           if (fade > 0.0) {
               edgeUv.z = 0.0;
           }
           vec4 col3 = texture2D(quiltTexture, texArr(edgeUv)); 
           float fadeLerp = clamp((abs(fade) - 1.0) * 2.3, 0.0, 1.0);
           vec4 privacylerp = mix(col3, privacy, 0.95);
           col1 = mix(col1, privacylerp, abs(fadeLerp));
       }
       if (isvid) {
        gl_FragColor = col1;
       }
       else {
        gl_FragColor = linearToOutputTexel( col1 );
       } 
   }`