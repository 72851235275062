/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { quilt_vertShader, quilt_fragShader} from '../shaders/quilt_shader'
import { Glass_Reflection, Mirror_Reflection } from '../mirrors'

export default function LKG_8k({...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/LKG_8K.glb')

  var test_material = new THREE.ShaderMaterial({
    uniforms: {
      quiltTexture: { value: props.quilt },
      tilesX: { value: props.columns },
      tilesY: { value: props.rows },
      aspectScaler: {value: new THREE.Vector2(((3840/2160)/props.aspect), 1)},
      quiltViewPortion: { value: new THREE.Vector2(4095.0 / 4096.0, 4095.0 / 4096.0) }
    },
    vertexShader: quilt_vertShader,
    fragmentShader: quilt_fragShader
  })

  // assign shader  to display and adjust glass transparency/color
  materials['Material.003'] = test_material

  return (
    <group {...props} ref={group} dispose={null}>
       <group rotation={[1.309, 3.14 / 2, 0]} position={[0, 9, 2.6]}>
          <Glass_Reflection height={15} width={27}/>
      </group>
        {/* Top Mirror */}
      <group rotation={[2.87979, 3.14 / 2, 0]} position={[0, 16.1, 1.2]}>
        <Mirror_Reflection height={1.8} width={27} />
      </group>
        {/* Bottom Mirror */}
        <group rotation={[6.02139, 3.14 / 2, 0]} position={[0, 1.75, 4.8]}>
        <Mirror_Reflection height={1.8} width={27} />
      </group>
        {/* Right Side Mirror */}
      <group rotation={[6.02139, 0, 1.5708]} position={[13.4, 8.9, 3.0]}>
        <Mirror_Reflection height={15} width={2.0} />
      </group>
       {/* Left Side Mirror */}
       <group rotation={[6.02139, 0, -1.5708]} position={[-13.4, 8.9, 3.0]}>
        <Mirror_Reflection height={15} width={2.0} />
      </group>
      <group scale={[39.37, 39.37, 39.37]}>
        <mesh
          geometry={nodes.LKG_8K_FRAME.geometry}
          material={nodes.LKG_8K_FRAME.material}
          position={[0, 0.21353, 0.02177]}
          rotation={[1.309, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_Back.geometry}
          material={nodes.LKG_8K_Back.material}
          position={[0, 0.92394, 3.25255]}
          rotation={[-0.2618, 0, 0]}
          scale={0.0254}
        />
        <mesh
          geometry={nodes.LKG_8K_BasePlate.geometry}
          material={materials['Material.002']}
          position={[0.02817, 0.01874, 0.07215]}
          rotation={[1.309, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8k_Plate.geometry}
          material={nodes.LKG_8k_Plate.material}
          position={[0.01112, -0.00196, 0.0096]}
          rotation={[1.309, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8k_Display.geometry}
          material={materials['Material.003']}
          position={[0, 0.21353, 0.02177]}
          rotation={[1.309, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_MIRROR.geometry}
          material={materials.Mirror}
          position={[0, 0.21353, 0.02177]}
          rotation={[1.309, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_BTN.geometry}
          material={nodes.LKG_8K_BTN.material}
          position={[0.36281, 0.23659, 0.05933]}
          rotation={[1.30365, -0.00114, 1.78023]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_LED.geometry}
          material={nodes.LKG_8K_LED.material}
          position={[0.36281, 0.23659, 0.05933]}
          rotation={[1.30365, -0.00114, 1.78023]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_BTN001.geometry}
          material={nodes.LKG_8K_BTN001.material}
          position={[0.36281, 0.2897, 0.0451]}
          rotation={[1.30365, -0.00114, 1.78023]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_LED001.geometry}
          material={nodes.LKG_8K_LED001.material}
          position={[0.36281, 0.2897, 0.0451]}
          rotation={[1.30365, -0.00114, 1.78023]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_BTN002.geometry}
          material={nodes.LKG_8K_BTN002.material}
          position={[0.36279, 0.31387, 0.03871]}
          rotation={[1.30365, -0.00114, 1.78023]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_LED002.geometry}
          material={nodes.LKG_8K_LED002.material}
          position={[0.36279, 0.31387, 0.03871]}
          rotation={[1.30365, -0.00114, 1.78023]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh geometry={nodes.LKG_SIDE.geometry} material={nodes.LKG_SIDE.material} position={[0, 0.92394, 3.25255]} rotation={[-0.2618, 0, 0]} scale={0.0254} />
        <mesh
          geometry={nodes.LKG_8K_LED001_1.geometry}
          material={nodes.LKG_8K_LED001_1.material}
          position={[0, 0.21353, 0.02177]}
          rotation={[1.309, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
        <mesh
          geometry={nodes.LKG_8K_BackPanel.geometry}
          material={nodes.LKG_8K_BackPanel.material}
          position={[0, 0.21353, 0.02177]}
          rotation={[1.309, 0, 0]}
          scale={[0.0254, 0.0254, 0.0254]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/LKG_8K.glb')
