import { Reflector } from '@react-three/drei'

export function Mirror_Reflection(props) {
  return (
    <Reflector resolution={256} args={[props.width, props.height]} mirror={0} mixStrength={3} mixBlur={0} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
      {(Material, props) => <Material color="#6e6e6e" metalness={.45} roughness={1} toneMapped={false} {...props} />}
    </Reflector>
  )
}

export function Glass_Reflection(props) {
  return (
    <Reflector resolution={512} args={[props.width, props.height]} mirror={0.4} mixStrength={0.5} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
      {(Material, props) => (
        <Material
          color="#B4B4B4"
          metalness={0}
          roughness={0}
          opacity={0.2}
          transparent={true}
          toneMapped={false}
          {...props}
          //TO DO: make reflector shader support these params transmission={.5} ior={1.5} thickness={0.01} envMapIntensity={1}
        />
      )}
    </Reflector>
  )
}
