import { LightfieldPlane } from './components/lightfield.js'
import LKG_4k from './components/models/LKG_4k.js'
import LKG_8k from './components/models/LKG_8k.js'
import { Suspense } from 'react';
import Portrait from './components/models/Portrait.js'
import { Environment } from '@react-three/drei';
import HDRI from './textures/cannon_1k.hdr'

export default function LookingGlass({...props}) {

return (
<group>
    {/* The suspense component is needed since the quilt takes some time to load, we should look at optimizing this or loading the portrait and *then* loading the quilt */}
    <Suspense fallback={'loading...'}>
        {/* the below component is the Portrait 3D model, it has props of position, path, rows and columns */}
        <Portrait frames={props.frames} limit={props.limit} position={[0,0,0]} quilt={props.quilt} columns={props.columns} rows={props.rows} aspect={props.aspect} xshift={props.xshift} yshift={props.yshift} reflections={props.reflections}/>
        {/* <LKG_4k position={[15,0,0]} quilt={props.quilt} columns={props.columns} rows={rows} aspect={props.aspect} reflections={props.reflections}/> */}
        {/* <LKG_8k position={[0,0,0]} quilt={props.quilt} columns={props.columns} rows={props.rows} aspect={props.aspect} reflections={props.reflections}/> */}
        {/* <LightfieldPlane aperture={aperture} focus={focus} fieldTexture={fieldTexture}/> */}
        <Environment files={HDRI}/>
    </Suspense>
</group>
)}