import { render } from 'react-dom'
import React, { Suspense, useState, useEffect } from 'react'
import useCapture from 'use-capture'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import * as THREE from 'three'
import { Stats } from '@react-three/drei'
import { LoadQuilt, ExtractVideo, LoadLightfieldVideo } from './components/filehandling.js'
import { checkImage } from './components/urlhandling.js'
import LookingGlass from './app'

function App() {
  const [bind, startRecording] = useCapture({ duration: 10, fps: 24 })
  const [quilt, setQuilt] = useState(new THREE.TextureLoader().load('/j_smf_lightfield_qs5x9a0.75.webp'))
  const [columns, setColumns] = useState(5)
  const [rows, setRows] = useState(9)
  const [aspect, setAspect] = useState(0.75)
  const [xshift, setXshift] = useState(0.5)
  const [yshift, setYshift] = useState(0.5)
  const [hologramURL, setHologramURL] = useState('')
  const [aperture, SetAperture] = useState(0.5)
  const [focus, SetFocus] = useState(0.6)
  const [lightfieldFile, SetLightfieldFile] = useState()
  const [fieldTexture, SetFieldTexture] = useState()
  const [webgl, SetWebgl] = useState()
  const [reflections, SetReflections]= useState(true)
  const [mute, SetMute] = useState(true)

// default quilt params
console.log( "portrait rendered")
  var quilt_tex
  const vid = document.createElement('video')
  vid.setAttribute = ("class", "media-document mac video")
  vid.muted = mute
  quilt_tex = new THREE.TextureLoader().load('/j_smf_lightfield_qs5x9a0.75.webp')
  quilt_tex.format = THREE.RGBAFormat

// attempt to load quilt from url TODO: make more robust, it will curently load any url even if no file is present.
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const urlQuilt = urlParams.get('quilt')
    if (urlParams.get('quilt') != undefined) {
      checkImage(urlQuilt,setQuilt, setColumns, setRows)
      setHologramURL(urlQuilt)
    }
    // modify this later so these only get called if the regex in checkImage fails.
    if (urlParams.get('rows') != undefined) {
      setRows(urlParams.get('rows'))
    } 
    if (urlParams.get('columns') != undefined) {
      setColumns(urlParams.get('columns'))
    }
  }, [])

// Update URL params for sharing
function UpdateURL() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  urlParams.set('columns', columns)
  urlParams.set('rows', rows)
  urlParams.set('quilt', hologramURL)
  window.history.replaceState({}, '', `${location.pathname}?${urlParams}`)
}

  return (
    <>
    <div className="banner"><p className="_experimental_">🧪Experimental: This project is still under active development!</p></div>
      <Canvas
      mode="concurrent"
      classname={'banana'}
      dpr={[1, 2]}
      camera={{ position: [0, 4, 12], target: [0, 3, 0], fov: 40 }}
      gl={{ preserveDrawingBuffer: true}}
      onCreated={bind}
      shadows={false}
      shadowMap
      >
        <LookingGlass frames={1} limit={50} quilt={quilt} columns={columns} rows={rows} aspect={aspect} bind={bind} reflections={reflections}/>
        {/* General scene setup with ambient lighting */}
        <mesh rotation={[-Math.PI/2,0,0]} castShadow receiveShadow>
          <planeGeometry args={[150, 150, 150]}/>
          <meshPhysicalMaterial color={'white'} roughness={1}/>
        </mesh>
        {/* additional light */}
        <directionalLight position={[25, 100, 0]} intensity={1} castShadow={true} shadowCameraNear={0} shadowCameraFar={200}/>
        <hemisphereLight color={'white'} intensity={3}/>
        {/* Orbit Controls can be added if we want users to have control over the model,we probably don't want this for the gen2 website, but this may be cool for hologram.supply */}
        <OrbitControls target={[0, 3, 0]} minPolarAngle = {Math.PI/3} maxPolarAngle = {Math.PI/3} enablePan={false} enableZoom={false}/>
      </Canvas>
      <Stats />
      <div style={{ color: 'black', margin: '20px', maxwidth: '500px', paddingBottom: '70px' }}>
      <button className={'recordButton'} onClick={startRecording}>
        ⏺️ Start Recording
      </button>
      <label for="file">Choose File</label>
      <input type="file" id="file" onChange={(e) => LoadQuilt(e.target.files?.item(0), setQuilt, setColumns, setRows, vid, webgl)} />
      <label for="columns">columns</label>
      <input type="number" id="columns" value={columns} onChange={(e) => setColumns(e.target.valueAsNumber)} />
      <label>rows</label>
      <input type="number" id="rows" value={rows} onChange={(e) => setRows(e.target.valueAsNumber)} />
      <label for="aspect">aspect ratio</label>
      <input type="number" id="aspect" value={aspect} onChange={(e) => setAspect(e.target.valueAsNumber)} />
      <label for="XShift">X shift</label>
      <input type="number" id="XShift" value={xshift} onChange={(e) => setXshift(e.target.valueAsNumber)} />
      <label for="YShift">Y shift</label>
      <input type="number" id="YShift" value={yshift} onChange={(e) => setYshift(e.target.valueAsNumber)} />
      <label for="URL">Load quilt from URL</label>
      <input type="text" id="URL" value={hologramURL} onChange={(e) => setHologramURL(e.target.value)} />
      <button onClick={(e) => UpdateURL()}>Update URL Parameters</button>
      <button onClick={(e) => SetReflections(!reflections)}>toggle reflections</button>
      <button onClick={(e) => vid.play()}>Play Video</button>
      <button onClick={(e) => SetMute(!mute)}>Toggle Audio</button>
      {/* <div>
        <div id="load-wrap"> The Tools below are for experimental Lightfield Import, the file needs to be an mp4 with sequential frames. It is still a major WIP. 
          <input type="file" id='load' onChange={(e) => LoadLightfieldVideo(e.target.files?.item(0), SetLightfieldFile, SetFieldTexture)}/>
          <button onClick={(e) => console.log(fieldTexture)}>check state of light field</button>
        </div>
        <div>
          <label htmlFor='aperture'>Aperture</label>
          <input type='range' min='0' max='10' step='0.1' id='aperture' onChange={(e) => SetAperture(e.target.valueAsNumber)} />
        </div>
        <div>
          <label htmlFor='focus'>Focus</label>
          <input type='range' min='-0.01' max='0.01' step='0.0001' id='focus' onChange={(e) => SetFocus(e.target.valueAsNumber)}/>
        </div>
      </div> */}
      <div>
        instructions <br />
        <ul style={{ color: 'black' }}>
          Both Images and Videos can be used! Some Quilt videos will have playback issues or stutters due to browser limitations with high resolution video playback. Video resolutions higher than 4096x4096 are not recommended.
        </ul>
        <ul style={{ color: 'black' }}>
          Loading a Quilt that does not have the proper file syntax will not be parsed. This can be manually updated with the Columns and Rows input boxes.{' '}
        </ul>
        <ul style={{ color: 'black' }}>
          Please submit feedback, bugs or feature requests to the following{' '}
          <a href="https://form.asana.com/?k=d4oHz7IP7XDifRA1W8vLLQ&d=549648722971027">form</a>.
        </ul>
      </div>
      </div>
    </>
  )
}

render(<App />, document.querySelector('#root'))
